import React from 'react';
import Merchants_Navbar from '../../../components/Merchants_Navbar';
import cwagatewaybg from '../../../images/cwa-gateway/cwa-gateway-bg.jpg'
import Layout from '../../../components/Layout';
import { Link } from 'gatsby';
import vtOverview from '../../../static/CWA-Gateway-Virtual-Terminal-Overview1.pdf'
import recurringBilling from '../../../static/CWA-Gateway-Recurring-Billing1.pdf'
import processingSale from '../../../static/CardWorks-Gateway-Processing-a-Sale1.pdf'
import mobileGateway from '../../../static/CWA-Gateway-Mobile1.pdf'
import VisaArticle from '../../../static/Updates-to-Fraud-and-Consumer-Dispute-Rules-042023.pdf'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFilm } from '@fortawesome/free-solid-svg-icons';

function Index(props) {
    let styles ={
        section2:{
            backgroundImage:`url(${cwagatewaybg})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
        }
    }
    return (
        <div>
            <Layout location={'Merchants'} title={'CWA Gateway'}>
            <Merchants_Navbar location={'cwa-gateway'}/>
            <section className="cw-bg-bluegradient text-center py-10 px-5">
                <div className="text-center w-100">
                    <h1 className='text-5xl text-white font-bold'> CWA Gateway</h1>
                    {/* <img src={cwagatewaylogo} alt="CWA Gateway" className='mx-auto w-96 h-auto'/> */}
                    {/* <h1 className="text-5xl text-white font-bold pb-2">Partnering for Success</h1>
                    <h2 className="text-3xl text-white font-semibold">The Makings of a Prosperous Career in Sales</h2>
                    <div className='flex justify-center pt-5'>
                        <iframe title="CWA-MS Partner Program Video" src="https://player.vimeo.com/video/125490748?h=014457da2a&amp;dnt=1&amp;app_id=122963" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe>
                    </div> */}

                </div>
            </section>
            <section style={styles.section2} className='py-20'>
                <div className="max-w-2xl mx-auto px-5 text-center">
                    <div className='mb-7'>
                        <h2 className="text-3xl text-white font-bold mb-3">Welcome to the NEW CWA Gateway!</h2>
                        <p className="font-serif text-white text-xl leading-8">
                            CWA Merchant Services is proud to announce the launch of the newest addition to our ever-growing suite of products.  
                            All designed to help YOU run your business more efficiently and process credit card transactions with ease!
                        </p>
                    </div>

                    <div>
                        <h2 className="text-3xl text-white font-bold mb-3">Welcome to the NEW CWA Gateway!</h2>
                        <p className="font-serif text-white text-xl leading-8">Contact Merchant Support:</p>
                        <p className="font-serif text-white text-xl leading-8">(866) 210 – 4625 (EXT. 1)</p>
                        <p className="font-serif text-white text-xl leading-8 mb-3">merchantsupport@cwams.com</p>
                        <Link to="/cwa-gateway-log-in"><button className="btn-lightblue">Login to CWA Gateway</button></Link>
                    </div>
                </div>
            </section> 
            {/* <section className='bg-gray-100 py-20'>
                <div className='max-w-3xl mx-auto px-5'>
                    <h2 className="text-3xl cw-text-lightblue font-bold mb-5 text-center">Informative Documents about CWA Gateway</h2>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-3 px-10 text-left'>
                    <span className='flex justify-start '><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilePdf}/><a href={vtOverview} target="_blank" className='font-semibold text-lg text-blue-800 hover:underline'>CWA Gateway Virtual Terminal Overview</a></span>
                    <span className='flex justify-start '><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilePdf}/><a href={recurringBilling} target="_blank" className='font-semibold text-lg text-blue-800 hover:underline'>CWA Gateway Recurring Billing</a></span>
                    <span className='flex justify-start '><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilePdf}/><a href={processingSale} target="_blank" className='font-semibold text-lg text-blue-800 hover:underline'>CWA Gateway Processing a Sale</a></span>
                    <span className='flex justify-start '><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilePdf}/><a href={mobileGateway} target="_blank" className='font-semibold text-lg text-blue-800 hover:underline'>CWA Gateway Mobile</a></span>
                    </div>
                </div>
            </section>  */}
            <section className='py-20'>
                <div className='max-w-3xl mx-auto px-5'>
                    <h2 className="text-3xl cw-text-lightblue font-bold mb-5 text-center">Training Videos</h2>
                    <div className='grid grid-cols-1 lg:grid-cols-2 gap-y-3 px-10 text-left'>
                        <span className='flex justify-start'><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilm}/><Link to="https://cwamerchantservices.transactiongateway.com/merchants/videos/ProcessSale2_demo.htm" className='font-semibold text-lg text-blue-800 hover:underline'>Sale Transactions</Link></span>
                        <span className='flex justify-start'><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilm}/><Link to="https://cwamerchantservices.transactiongateway.com/merchants/videos/Void_demo.htm" className='font-semibold text-lg text-blue-800 hover:underline'>Void a Transaction</Link></span>
                        <span className='flex justify-start'><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilm}/><Link to="https://cwamerchantservices.transactiongateway.com/merchants/videos/Refund_demo.htm" className='font-semibold text-lg text-blue-800 hover:underline'>Refund a Transaction</Link></span>
                        <span className='flex justify-start'><FontAwesomeIcon className="pt-2 pr-2 text-blue-800" icon={faFilm}/><Link to="https://cwamerchantservices.transactiongateway.com/merchants/videos/TestMode2_demo.htm" className='font-semibold text-lg text-blue-800 hover:underline'>Entering Test Mode</Link></span>
                    </div>
                </div>
            </section> 
            </Layout>
        </div>
    );
}

export default Index;